.CustomersEdit{
    width: 100%;
    margin-left: 270px;
    overflow: hidden;
}
.CustomersEdit-content{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}
.CustomersEdit-content form{

    width: 100%
}
.CustomersEdit-content form h2{
    margin-bottom: 20px;
    color: #4C5569;
}
.CustomersEdit-content form label{
    display: block;
    width: 100%;
    margin-top: 20px;
}
.CustomersEdit-content label input{
    width: 100%;
    padding: 10px 20px;
    border-radius: 10px;
    border: 2px solid #DCE1EF;
    outline-color: #2563EB;
}
.CustomersEdit-content form label h3{
    color: #4C5569;
    font-weight: 400;
    cursor: pointer;
    margin-bottom: 2px;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.CustomersEdit-content form textarea{
    resize: none;
    overflow: hidden;
    width: 100%;
    height: 100px;
    padding: 10px 20px;
    border-radius: 10px;
    border: 2px solid #DCE1EF;
    outline-color: #2563EB;
}
.CustomersEdit-content form button{
    width: 100%;
    border-radius: 10px;
    border: 2px solid #2563EB;
    padding: 10px 20px;
    background-color: #2563EB;
    color: white;
    font-size: 15px;
    transition: 0.5s;
    margin-top: 30px;
}
.CustomersEdit-content form button:hover{
    background-color: transparent;
    color: #2563EB;
}
.CreateCustoers-content-grid{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}
@media(max-width:1100px){
    .CustomersEdit{
        margin-left: 0;
    }
}
@media(max-width:900px){
    .CustomersEdit-content form{
        width: 90%;
    }
}
@media(max-width:300px){
    .CustomersEdit-content form h2{
        font-size: 20px;
    }
    .CustomersEdit-content form h3{
        font-size: 15px;
    }
}