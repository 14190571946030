.ContractEdit{
    width: 100%;
    margin-left: 270px;
    overflow: hidden;
}
.ContractEdit-content{
    width: 100%;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    gap: 30px;
    margin-top: 50px;
    padding: 0px 30px;
}
.ContractEdit-content form{
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #DCE1EF;
    width: 50%;
    height: fit-content;
}
.ContractEdit-content form h2{
    margin-bottom: 20px;
    color: #4C5569;
}
.ContractEdit-content form label{
    display: block;
    width: 100%;
    margin-top: 20px;
}
.ContractEdit-content label input{
    width: 100%;
    padding: 10px 20px;
    border-radius: 10px;
    border: 2px solid #DCE1EF;
    outline-color: #2563EB;
}
.ContractEdit-content form label h3{
    color: #4C5569;
    font-weight: 400;
    cursor: pointer;
    margin-bottom: 2px;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.ContractEdit-content form textarea{
    resize: none;
    overflow: hidden;
    width: 100%;
    height: 100px;
    padding: 10px 20px;
    border-radius: 10px;
    border: 2px solid #DCE1EF;
    outline-color: #2563EB;
}
.ContractEdit-content form button{
    width: 100%;
    border-radius: 10px;
    border: 2px solid #2563EB;
    padding: 10px 20px;
    background-color: #2563EB;
    color: white;
    font-size: 15px;
    transition: 0.5s;
    margin-top: 30px;
}
.ContractEdit-content form button:hover{
    background-color: transparent;
    color: #2563EB;
}
.ContractEdit-text{
    background-color: white;
    width: 50%;
    height:100%;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #DCE1EF;
    height: fit-content;
}
.ContractEdit form{
    width: 100%;
}
@media(max-width:1100px){
    .ContractEdit{
        margin-left: 0;
    }
}
@media(max-width:900px){
    .ContractEdit-content{
        flex-direction: column;
        gap: 30px;
    }
    .CreateContracts-text{
        width: 100%;
    }
    .CreateContracts-text form{
        width: 100%;
    }
    .CreateContracts2{
        width: 100%;
    }
}
@media(max-width:300px){
    .ContractEdit-content form h2{
        font-size: 20px;
    }
    .ContractEdit-content form h3{
        font-size: 15px;
    }
}