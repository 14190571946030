.Profile{
    width: 100%;
    margin-left: 270px;
    padding-bottom: 30px;
    overflow: hidden;
}
.Profile-content{
    padding: 0 30px;
}
.Profile-img{
    /* margin-top: 50px; */
}
.Profile-img-content{
    background-color: white;
    padding: 20px;
    text-align: center;
    border: 1px solid #DCE1EF;
    border-radius: 10px;
}
.Profile-img-content img{
    width: 350px;
    height: 400px;
    object-fit: cover;
}
.Profile-content h1{
    color: #32373f; 
}
.Profile-img-saidbar{
    margin-top: 50px;
    border: 1px solid #DCE1EF;
    width: 400px;
    /* padding: 20px; */
    background-color: white;
    gap: 5px;
    border-radius: 10px;
}
.Profile-img-saidbar li{
    width: 100%;
    background-color: transparent;
    border: none;
    padding:  20px ;
    /* background-color: #4c556996; */
    border-radius: 2px;
    cursor: pointer;
}
.Profile-img-saidbar li:hover{
      background-color: #4c556996;
}
.Profile-img-saidbar-active{
    background-color: #4C5569 !important;
    color: white;
}
.Profile-content{
    margin-top: 30px;
    display: flex;
    /* align-items: center; */
    gap: 10px;
    width: 100%;
    /* justify-content: space-between; */
}
.Profile-form{
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #DCE1EF;
    height: fit-content;
    width: 100%;
    display: block;
}
.Profile-form form label{
    display: block;
    width: 100%;
    margin-top: 20px;
}
.Profile-form label input{
    width: 100%;
    padding: 10px 20px;
    border-radius: 10px;
    border: 2px solid #DCE1EF;
    outline-color: #2563EB;
}
.Profile-form form label h3{
    color: #4C5569;
    font-weight: 400;
    cursor: pointer;
    margin-bottom: 2px;
}

.Profile-form-grid{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
}
.Profile-form form button{
    width: 100%;
    border-radius: 10px;
    border: 2px solid #2563EB;
    padding: 10px 20px;
    background-color: #2563EB;
    color: white;
    font-size: 15px;
    transition: 0.5s;
    margin-top: 30px;
    cursor: pointer;
}
.Profile-form form button:hover{
    background-color: transparent;
    color: #2563EB;
}
.Profile-content-dn{
    display: none;
}
.Profile-Password{
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #DCE1EF;
    height: fit-content;
    width: 100%;
    display: none;
}
.Profile-Password form label{
    display: block;
    width: 100%;
    margin-top: 20px;
}
.Profile-Password label input{
    width: 100%;
    padding: 10px 20px;
    border-radius: 10px;
    border: 2px solid #DCE1EF;
    outline-color: #2563EB;
}
.Profile-Password form  h3{
    color: #4C5569;
    font-weight: 400;
    cursor: pointer;
    margin-bottom: 2px;
}
.Profile-Password form button{
    width: 100%;
    border-radius: 10px;
    border: 2px solid #2563EB;
    padding: 10px 20px;
    background-color: #2563EB;
    color: white;
    font-size: 15px;
    transition: 0.5s;
    margin-top: 30px;
    cursor: pointer;
}
.modal-foto h3{
    color: #4C5569;
    font-weight: 400;
    cursor: pointer;
    margin-bottom: 2px;
}
.Profile-Password form button:hover{
    background-color: transparent;
    color: #2563EB;
}
.Profile-Password-dn{
    display: block;
}
.modal-foto {
    margin-top: 20px;
    width: 100%;
    cursor: pointer;
}
.file-input-container {
    position: relative;
    overflow: hidden;
    display: inline-block;
    padding: 10px;
    border: 1px solid #2563EB;
    border-radius: 5px;
    background-color: #2563EB;
    cursor: pointer;
    color: black;
    width: 300px;
    cursor: pointer;
    transition: 0.5s;
    margin-top: 3px !important;
}
.file-input-container input[type=file] {
    cursor: pointer;
    position: absolute;
    font-size: 100px;
    opacity: 0;
    right: 0;
    top: 0;
    cursor: pointer;
}

.file-input-container span {
    display: block;
    text-align: center;
    cursor: pointer;
    font-size: 20px;
    color: black;
}

.file-input-container:hover {
    background-color: transparent;
    color: #2563EB;
    cursor: pointer;
}
.file-input-container:hover + .fotoname{
        color: #2563EB;
}
@media(max-width:1200px){
    .Profile-form-grid{
        flex-direction: column;
        gap: 1px;
    }
}
@media(max-width:1100px){
    .Profile{
        margin-left: 0;
    }
}
@media(max-width:950px){
    .Profile-img img{
        width: 300px;
        height: 350px;
    }
}
@media(max-width:870px){
    .Profile-content{
        flex-direction: column;
        gap: 10px;
    }
    .Profile-img-saidbar{
        width: 100%;
    }
}
@media(max-width:410px){
    .Profile-img img{
        width: 200px;
        height: 250px;
    }
}
@media(max-width:310px){
    .Profile-img img{
        width: 100px;
        height: 150px;
    }
}