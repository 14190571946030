.header{
    background-color: white;
    width: 100%;
    padding: 10px;
    padding-right: 50px;
    /* overflow: hidden; */
    border: 1px solid #DCE1EF;
}

.header-acount img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.header-acount{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid blue;
    padding: 3px;
 
}
.header-search label{
    background-color: #EFF2F6;
    padding: 10px 20px;
    border-radius:10px ;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 400px;
}
.header-search label input{
    outline: none;
    border: none;
    background-color: #EFF2F6;
    font-size: 15px;
    width: 100%;
}
.header-search svg{
    font-size: 25px;
    color: #4C5569;
    cursor: pointer;
}
.header-search{
    display: flex;
    align-items: center;
    gap: 20px;
}
.header-serch-button{
    padding: 1px    10px;
    color: white;
    background-color: #2563EB;
    border: none;
    border-radius: 50%;
    font-size: 30px;
    cursor: pointer;
}
.header-serch-btn-wrapper{
    position: relative;
}
.header-search-button-modal{
    background-color: #172032;
    color: white;
    padding: 10px;
    border-radius: 10px;
    position: absolute;
    top: 50px;
    left: -55px;
    width: 145px;
    /* display: none; */
    opacity: 0;
    transition: 0.2s;
    z-index: -1;
}
.header-serch-btn-wrappe:hover   {
    display: block !important; 
    opacity: 1;
}
.header-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header-search-active-modal{
    background-color: #172032;
    position: absolute;
    border-radius: 6px;
    left: -55px;
    top: 45px;
    display: none;
    /* opacity: 0; */
    transition: 0.2s;
    z-index: 2;
}
.header-search-active-modal a{
    display: flex;
    align-items: center;
    justify-content: baseline;
    gap: 5px;
    color: white;
    padding: 10px 25px;
    width: 100%;
    border-radius: 6px;
}
.header-search-active-modal svg{
    color: white;
}
.ModalActive{
    display: block;
    /* opacity: 1; */
}
.header-search-active-modal a:hover{
    background-color: #4C5569;

}
.hoverActiver {
    opacity: 1;
    z-index: 1;
}
.header-profile{
    position: relative;
}
.header-profile-modal{
    position: absolute;
    top: 60px;
    right: -35px;
    opacity: 0;
    background-color: #172032;
    padding: 10px 20px;
    z-index: 10;
    width: 120px;
    border-radius:10px ;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;

}
.header-profile-modal span{
    color: white;
}
.header-profile-modal-active{
    opacity: 1;
}
.header-acount{
    cursor: pointer;
}
.heaedr-profile-modal-active{
    opacity: 0;
    position: absolute;
    top: 60px;
    right: -40px;
    width: 150px;

    background-color: #172032;
    z-index: -1;
    border-radius: 10px;
}
.heaedr-profile-modal-active a{
    display: block;
    color: white !important ;
    padding: 10px 20px;
    border-radius: 10px;
}
.heaedr-profile-modal-active a:hover{
    background-color: #4C5569;   
}
.header-profile-modal-active-start{
    opacity: 1;
    z-index: 11;
}
.Hamburger-header{
    display: none;
}
.active-class{

}
.header-wrapper{
    gap: 10px;
}
.Saidbar_bg{
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.897);
    z-index: 10;
    transition: all 1s ease;
    transform: translateX(-100%);
}
.Saidbar_bg_Active{
    transform: translateX(0%) !important;
}
.x{
    position: fixed;
    right: 20px;
    top: 20px;
    color: white;
    z-index: 100;
}
.Hamburger-header svg{
    font-size: 35px;
}
@media(max-width:1100px){
    .Hamburger-header{
        display: block;
    }
}
@media(max-width:670px){
    .header-search{
        width: 400px;
    }
    .header-search input{
        width: 100%;
    }
    .header-profile-modal-active{
        display: none !important;
    }
    .heaedr-profile-modal-active{
        right: 0px;
    }
    .heaedr-profile-modal-active a{
        font-size: 12px;
        /* width: 100px !important; */
        padding: 7px 10px;
    }
    .header-profile-modal-active-start{
        width: 100px;
    }
}
@media(max-width:500px){
    .header-search{
        width: 250px;
    }
}
@media(max-width:620px){
    .header-search button{
        display: none;
    }
    .header{
        padding-right: 5px;
    }
}
@media(max-width:350px){
    .header-search label{
        display: none;
    }
}