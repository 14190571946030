.CreateContracts{
    width: 100%;
    margin-left: 270px;
    overflow: hidden;
}
.CreateContracts-content{
    width: 100%;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    gap: 30px;
    margin-top: 50px;
    padding: 0px 30px;
}
.CreateContracts-content form{
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #DCE1EF;
    width: 100%;
    height: fit-content;
}
.CreateContracts2{
    width: 50%;
}
.grid_inp{
    display: flex;
    align-items: center;
    gap: 10px;
}
.CreateContracts-content form h2{
    margin-bottom: 20px;
    color: #4C5569;
}
.CreateContracts-content form label{
    display: block;
    width: 100%;
    margin-top: 20px;
}
.CreateContracts-content label input{
    width: 100%;
    padding: 10px 20px;
    border-radius: 10px;
    border: 2px solid #DCE1EF;
    outline-color: #2563EB;
}
.CreateContracts-content form label h3{
    color: #4C5569;
    font-weight: 400;
    cursor: pointer;
    margin-bottom: 2px;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.CreateContracts-content form textarea{
    resize: none;
    overflow: hidden;
    width: 100%;
    height: 100px;
    padding: 10px 20px;
    border-radius: 10px;
    border: 2px solid #DCE1EF;
    outline-color: #2563EB;
}
.CreateContracts-content form button{
    width: 100%;
    border-radius: 10px;
    border: 2px solid #2563EB;
    padding: 10px 20px;
    background-color: #2563EB;
    color: white;
    font-size: 15px;
    transition: 0.5s;
    margin-top: 30px;
}
.CreateContracts-content form button:hover{
    background-color: transparent;
    color: #2563EB;
}
.CreateContracts-text{
    background-color: white;
    width: 50%;
    height:100%;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #DCE1EF;
    height: fit-content;
}
.CreateContract-saidbar{
    width: 100%;
    background-color: white;
    width: 100%;
    /* padding: 10px; */
    border-radius: 10px;
    /* border: 1px solid #DCE1EF; */
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.CreateContract-saidbar button{
    width: 100%;
    padding:10px 20px;
    background-color: white;
    border: 1px solid #DCE1EF;
    cursor: pointer;
}
.ConActive{
    background-color: #2563EB !important;
    color: white;
}
.etidH1{
    /* color: red !important; */
    text-align: center;
}
.center-text {
    text-align: center;
}
.fizperson{
    display: none;
}
.person{
    display: none;
}
.yozperson{
    display: block;
}
.yozperson-active{
    display: block;
}
.fizperson-active{
    display: block;
}
.dn{
    display: none;
}
.person-cative{
    display: block;
}
.CreateContract__foto{
    width: 100%;
    background-color: white;
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    margin-top: 20px;
}
.CreateContract__foto .modal-foto label{
    width: 100%;
}
.CreateContract__foto__number{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}
.CreateContract__foto__number span{
    font-weight: bold;
    font-size: 20px;
}
.CreateContract__foto__preview button{
    width: 100%;
    border-radius: 10px;
    border: 2px solid #2563EB;
    padding: 10px 20px;
    background-color: #2563EB;
    color: white;
    font-size: 15px;
    transition: 0.5s;
    margin-top: 30px;
    cursor: pointer;
}
.CreateContract__foto__preview button:hover{
    background-color: transparent;
    color: #2563EB;
}
@media(max-width:400px){
    .CreateContract-saidbar{
        display: flex;
        align-items: center;
        flex-direction: column;
        
    }
}
@media(max-width:1100px){
    .CreateContracts{
        margin-left: 0;
    }
}
@media(max-width:900px){
    .CreateContracts-content form{
        width: 100%;
    }
    .CreateContracts-content{
        flex-direction: column;
        gap: 30px;
    }
    .CreateContracts-text{
        width: 100%;
    }
    .CreateContracts-content form{
        width: 100%;
    }
    .CreateContracts2{
        width: 100%;
    }
}
@media(max-width:300px){
    .CreateContracts-content form h2{
        font-size: 20px;
    }
    .CreateContracts-content form h3{
        font-size: 15px;
    }
}