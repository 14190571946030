.Login{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Login-logo img{
    display: block;
    margin: 0 auto;
    width: 200px;
}
.Login-wrapper{
    width: 25%;
    /* text-align: center; */
    padding: 30px;
    border: 1px solid #DCE1EF;
    background-color: white;
    border-radius: 10px;
}
.Login form label{
    display: block;
    width: 100%;
    margin-top: 20px;
}
.Login form input{
    width: 100%;
    padding: 10px 20px;
    border-radius: 10px;
    border: 2px solid #DCE1EF;
    outline-color: #2563EB;
}
.Login form label h3{
    color: #4C5569;
    font-weight: 400;
    cursor: pointer;
    margin-bottom: 2px;
}
.Login h1{
    text-align: center;
    margin-top: 30px;
    color: #4C5569;
}
.Login form button{
    width: 100%;
    border-radius: 10px;
    border: 2px solid #2563EB;
    padding: 10px 20px;
    background-color: #2563EB;
    color: white;
    font-size: 15px;
    transition: 0.5s;
    margin-top: 30px;
    cursor: pointer;
}
.Login form button:hover{
    background-color: transparent;
    color: #2563EB;
}
@media(max-width:1000px){
    /* .Login img{
        width: 100%;
    } */
    .Login-wrapper{
        width: 90%;
    }
}
@media(max-width:430px){
    .Login img{
        width: 100%;
    }
}